document.addEventListener("livewire:init", () => {
    Livewire.hook("request", ({ options, respond }) => {
        const ignored_components = [
            "notifications.push-status",
            "central_user.presence",
        ];

        for (const ignored_component in ignored_components) {
            if (options.body.includes(ignored_components[ignored_component])) {
                return;
            }
        }

        window.dispatchEvent(
            new CustomEvent("pfd-loading-start", { detail: { loading: true } }),
        );
        respond(({ status, response }) => {
            window.dispatchEvent(
                new CustomEvent("pfd-loading-end", {
                    detail: { loading: false },
                }),
            );
        });
    });
});
