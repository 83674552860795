import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

Alpine.directive(
    "mapbox",
    (el, { expression }, { evaluateLater, effect, cleanup }) => {
        let getSettings = evaluateLater(expression);
        let map = null;

        effect(() => {
            if (map !== null) {
                map.remove();
            }
            getSettings((settings) => {
                map = new mapboxgl.Map({
                    accessToken: import.meta.env.VITE_MAPBOX_TOKEN,
                    container: settings?.id,
                    style: "mapbox://styles/mapbox/streets-v12",
                    center: [settings?.longitude, settings?.latitude],
                    zoom: 15,
                });
                new mapboxgl.Marker()
                    .setLngLat([settings?.longitude, settings?.latitude])
                    .addTo(map);
            });
        });

        cleanup(() => {
            if (map !== null) {
                map.remove();
            }
        });
    },
);
