import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.VITE_APP_ENV,
    release:
        document
            .querySelector("meta[name='version']")
            ?.getAttribute("content") ?? "planificador@0.0.0",
    replaysSessionSampleRate: import.meta.env.VITE_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
});

document.addEventListener("DOMContentLoaded", () => {
    let user_id =
        document
            .querySelector("meta[name='pfd.user.id']")
            ?.getAttribute("content") ?? null;

    if (user_id !== null) {
        Sentry.setUser({
            id: user_id,
            username:
                document
                    .querySelector("meta[name='pfd.user.username']")
                    ?.getAttribute("content") ?? null,
            email:
                document
                    .querySelector("meta[name='pfd.user.email']")
                    ?.getAttribute("content") ?? null,
        });
    }
});
